import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import BaseSideBarContent from '..';
import Preloader from '../../Preloader';
import { Select } from '../../Field';
import { BlueButton, WhiteButton, TextButton } from '../../Button';
import Dialog from '../../Dialog/Dialog';
import NewQuestionFormModel from '../../../models/NewQuestionForm/NewQuestionForm';
import NewQuestionForm from '../../NewQuestion';
import EditQuestionForm from '../../EditQuestion';
import Question from '../../../models/Question/QuestionItemModel';

import styles from './Questions.scss';

@inject('userStore', 'dialogStore')
@observer
class QuestionsSideBar extends React.Component {
    newForm = new NewQuestionFormModel({
        hooks: {
            onSuccess: (form) => {
                const { props: { userStore: { questions }, dialogStore } } = this;
                const { question } = form.values();
                return questions.create({ question })
                    .then(() => {
                        form.reset();
                        form.validate();
                        dialogStore.hide();
                    });
            },
        },
    });

    editForm = new NewQuestionFormModel({
        hooks: {
            onSuccess: (form) => {
                const { props: { userStore: { questions: { current } }, dialogStore } } = this;
                const { question } = form.values();
                return current.edit({ question })
                    .then(() => {
                        form.reset();
                        form.validate();
                        dialogStore.hide();
                    });
            },
        },
    });

    static propTypes = {
        userStore: PropTypes.shape({
            questions: PropTypes.shape({
                loadQuestions: PropTypes.func,
                create: PropTypes.func,
                edit: PropTypes.func,
                delete: PropTypes.func,
                loading: PropTypes.bool,
                deleting: PropTypes.bool,
                selected: PropTypes.shape({
                    value: PropTypes.number.isRequired,
                    label: PropTypes.string.isRequired,
                }),
                options: PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.number.isRequired,
                        label: PropTypes.string.isRequired,
                    }),
                ),
                current: PropTypes.oneOfType([PropTypes.instanceOf(Question), PropTypes.object]),
                setCurrent: PropTypes.func,
            }),
        }).isRequired,
        dialogStore: PropTypes.shape({
            show: PropTypes.func,
            hide: PropTypes.func,
        }).isRequired,
    };

    componentDidMount() {
        const { props: { userStore: { questions } } } = this;
        questions.loadQuestions();
    }

    handleChange = (selected) => {
        const { props: { userStore: { questions } } } = this;
        questions.setCurrent(selected.value);
    }

    openNewQuestionForm = () => {
        const { props: { dialogStore } } = this;
        return dialogStore.show('add-question');
    }

    openEditQuestionForm = () => {
        const {
            props: {
                dialogStore,
                userStore: { questions: { current } },
            },
            editForm: editQuestionForm,
        } = this;
        editQuestionForm.$('question').value = current.text;
        return dialogStore.show('edit-question');
    }

    openDeleteForm = () => {
        const { props: { dialogStore } } = this;
        return dialogStore.show('delete-question');
    }

    deleteQuestion = () => {
        const { props: { userStore: { questions }, dialogStore } } = this;
        questions.delete()
            .then(() => {
                dialogStore.hide();
            });
    }

    hideDialog = () => {
        const { props: { dialogStore } } = this;
        return dialogStore.hide();
    }

    render() {
        const {
            props: {
                userStore: {
                    questions: {
                        loading,
                        options,
                        selected,
                        deleting,
                    },
                },
            },
            newForm: newQuestionForm,
            editForm: editQuestionForm,
            handleChange,
            openNewQuestionForm,
            openEditQuestionForm,
            openDeleteForm,
            deleteQuestion,
            hideDialog,
        } = this;
        return (
            <BaseSideBarContent>
                <React.Fragment>
                    <Preloader progress={loading} />
                    <div className={styles.questions}>
                        <div className={styles.questions__list}>
                            <Select
                                isSearchable
                                showLabel
                                label="Выберите вопрос"
                                options={options}
                                value={selected}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className={styles.questions__edit}>
                            <WhiteButton
                                type="button"
                                text="Редактировать"
                                onClick={openEditQuestionForm}
                            />
                        </div>
                        <div className={styles.questions__add}>
                            <BlueButton
                                type="button"
                                text="Добавить вопрос"
                                onClick={openNewQuestionForm}
                            />
                        </div>
                    </div>
                    <Dialog
                        name="add-question"
                        header="Добавить вопрос"
                    >
                        <NewQuestionForm form={newQuestionForm} />
                    </Dialog>
                    <Dialog
                        name="edit-question"
                        header="Редактировать вопрос"
                    >
                        <React.Fragment>
                            <EditQuestionForm form={editQuestionForm} />
                            <div className={styles.questions__delete}>
                                <TextButton
                                    type="button"
                                    text="Удалить вопрос"
                                    onClick={openDeleteForm}
                                />
                            </div>
                        </React.Fragment>
                    </Dialog>
                    <Dialog
                        name="delete-question"
                        header="Подтверждение"
                    >
                        <div className={styles.delete}>
                            <div className={styles.delete__message}>
                                Вы точно хотите удалить этот вопрос?
                            </div>
                            <div className={styles.delete__footer}>
                                <WhiteButton
                                    className={styles.delete__action}
                                    type="button"
                                    disabled={deleting}
                                    text="Да"
                                    onClick={deleteQuestion}
                                />
                                <WhiteButton
                                    className={styles.delete__action}
                                    type="button"
                                    text="Нет"
                                    onClick={hideDialog}
                                />
                            </div>
                        </div>
                    </Dialog>
                </React.Fragment>
            </BaseSideBarContent>
        );
    }
}

export default QuestionsSideBar;
