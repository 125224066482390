/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';

import styles from './styles.scss';

const menuLinks = [
    {
        title: 'Вопрос/ответ',
        name: 'questions',
        path: '/questions',
        disabled: false,
    },
    {
        title: 'Пользователи',
        name: 'accounts',
        path: '/accounts',
        disabled: false,
    },
    {
        title: 'Статистика',
        name: 'history',
        path: '/history',
        disabled: false,
    },
];

@inject('appStore')
@withRouter
class MenuComponent extends React.Component {
    static propTypes = {
        appStore: PropTypes.shape({
            setFilterIsOpen: PropTypes.func,
            setMenuIsOpen: PropTypes.func,
            loadingState: PropTypes.bool,
        }).isRequired,
        match: PropTypes.shape({
            url: PropTypes.string,
        }).isRequired,
    };

    handleClick = item => (
        () => {
            const { props } = this;
            const {
                appStore,
                match,
            } = props;
            const windowWidth = window.innerWidth;
            if (
                item.path === match.url
                && windowWidth <= 768
            ) {
                appStore.setFilterIsOpen(false);
                appStore.setMenuIsOpen(false);
            }
        }
    )

    render() {
        return (
            <div
                className={`${styles.container}`}
            >
                <div
                    ref={(ref) => {
                        this.container = ref;
                    }}
                >
                    <div
                        className={styles.menuContainer}
                    >
                        {
                            menuLinks.map(item => (
                                <div
                                    className={styles.menuLink}
                                    key={`${item.name}`}
                                    data-rh={item.disabled === true ? 'В разработке ' : null}
                                >
                                    <NavLink
                                        to={item.path}
                                        activeClassName={styles.activeMenuLink}
                                        className={`${item.disabled === true ? styles.linkDisabled : ''}`}
                                        onClick={this.handleClick(item)}
                                    >
                                        <span>
                                            {item.title}
                                        </span>
                                    </NavLink>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuComponent;
