/* global FileReader */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import Input from './Input';

import styles from './Image.scss';
import textFieldStyles from './Field.scss';
import defaultStyles from '../../styles/default.scss';

@observer
class ImageField extends Component {
    @observable image = null;

    @observable imageName = '';

    static propTypes = {
        autoComplete: PropTypes.oneOf(['on', 'off']),
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        value: PropTypes.string,
        files: PropTypes.arrayOf(PropTypes.object),
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        reset: PropTypes.func,
        error: PropTypes.string,
        showError: PropTypes.bool,
    };

    static defaultProps = {
        autoComplete: 'off',
        disabled: false,
        readOnly: false,
        value: '',
        files: [],
        label: '',
        placeholder: '',
        onFocus: null,
        onChange: null,
        onBlur: null,
        onClick: null,
        reset: undefined,
        error: '',
        showError: true,
    }

    @action
    reset = () => {
        const { props: { reset } } = this;
        this.image = undefined;
        this.imageName = undefined;
        reset();
    }

    onChange = (e) => {
        const { props: { onChange } } = this;
        const input = e.target;
        if (input.files && input.files[0]) {
            this.imageName = input.files[0].name;
            const reader = new FileReader();
            reader.onload = (loaded) => {
                this.image = loaded.target.result;
            };
            reader.readAsDataURL(input.files[0]);
        }
        if (onChange) onChange(e);
    }

    render() {
        const {
            props: {
                id,
                label,
                error,
                showError,
                value,
                files,
                ...rest
            },
            reset,
            onChange,
            image,
            imageName,
        } = this;
        return !files || !files.length
            ? (
                <div
                    className={`
                        ${textFieldStyles.inputContainer}
                        ${error ? textFieldStyles.inputContainerError : ''}
                    `}
                >
                    <label
                        htmlFor={id}
                        className={styles.label}
                    >
                        {label}
                    </label>
                    <Input
                        id={id}
                        value={value}
                        {...rest}
                        className={styles.input}
                        type="file"
                        onChange={onChange}
                    />
                    {
                        showError
                            ? (
                                <div className={defaultStyles.inputErrorText}>
                                    <span>
                                        {error}
                                    </span>
                                </div>
                            )
                            : null
                    }
                </div>
            )
            : (
                <div className={styles.loaded}>
                    <img
                        className={styles.loaded__image}
                        src={image}
                        alt="loaded"
                    />
                    <div className={styles.loaded__name}>
                        {imageName}
                    </div>
                    <button
                        type="button"
                        className={styles.loaded__reset}
                        onClick={reset}
                    />
                </div>
            );
    }
}

export default ImageField;
