import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import LoginFormModel from '../../models/LoginForm/LoginForm';
import LoginForm from './LoginForm';
import logo from '../../assets/images/logo.svg';

import styles from './Login.scss';

@inject('userStore')
class Login extends Component {
    form = new LoginFormModel({
        hooks: {
            onSuccess: (form) => {
                const { props: { userStore, onSuccess } } = this;
                const { email, password } = form.values();
                return userStore.login({
                    email,
                    password,
                })
                    .then(() => {
                        if (onSuccess) onSuccess();
                    });
            },
        },
    });

    static propTypes = {
        userStore: PropTypes.shape({
            login: PropTypes.func,
        }).isRequired,
        onSuccess: PropTypes.func,
    };

    static defaultProps = {
        onSuccess: null,
    }

    render() {
        const { form } = this;
        return (
            <React.Fragment>
                <div className={styles.logo}>
                    <img src={logo} alt="bbdo" />
                </div>
                <LoginForm form={form} />
            </React.Fragment>
        );
    }
}

export default Login;
