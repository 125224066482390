import { observable, computed, action } from 'mobx';
import withEventEmitter from '../../utils/with-event-emitter';
import QuestionList from '../Question/QuestionListModel';
import AccountList from '../Account/AccountListModel';
import History from '../History/HistoryListModel';

@withEventEmitter
class UserModel {
    @observable _loading = false;

    @observable _authorized = false;

    _questions = null;

    _accounts = null;

    _history = null;

    constructor(root) {
        this.root = root;
        const { root: { userService } } = this;
        userService.on('user:logout', this.handleLogout.bind(this));
        userService.on('request:error', this.handleError.bind(this));
        this.tryLogin();
    }

    @computed
    get isAuthorized() {
        return this._authorized;
    }

    @computed
    get questions() {
        return this._questions;
    }

    @computed
    get accounts() {
        return this._accounts;
    }

    @computed
    get history() {
        return this._history;
    }

    @action
    handleLogin() {
        this._authorized = true;
        this._questions = new QuestionList(this.root);
        this._accounts = new AccountList(this.root);
        this._history = new History(this.root);
        this.emit('user:logged-in');
    }

    @action
    handleLogout() {
        this._authorized = false;
        this._questions = undefined;
        this._accounts = undefined;
        this._history = undefined;
        this.emit('user:logged-out');
    }

    tryLogin() {
        const { root: { userService }, isAuthorized } = this;
        if (!userService.token) return Promise.resolve();
        if (isAuthorized) return Promise.resolve();
        this.handleLogin();
        this.emit('user:restore');
        return Promise.resolve();
    }

    login({ email, password }) {
        const { root: { userService } } = this;
        this.emit('user:login');
        return userService.login(email, password)
            .then(this.handleLogin.bind(this))
            .catch(() => {});
    }

    logout() {
        const { root: { userService } } = this;
        userService._resetToken();
        this.handleLogout();
        return Promise.resolve();
    }

    handleError(err) {
        console.error(err);
        this.emit('user:error', err);
    }
}

export default UserModel;
