import validatorjs from 'validatorjs';

validatorjs.prototype.setAttributeNames = function setAttributeNames(attributes) {
    if (!attributes) return;
    const modified = { ...attributes };
    const keys = Object.keys(modified);
    for (let i = 0; i < keys.length; i += 1) {
        const attribute = keys[i];
        modified[attribute] = modified[attribute].toLowerCase();
    }
    this.messages._setAttributeNames(modified);
};

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ({ validator }) => {
            validator.useLang('ru');
            validator.stopOnError(true);
        },
    },
};

export default plugins;
