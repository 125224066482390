import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class FormField extends Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
        validateOnBlur: PropTypes.bool,
        validateOnChange: PropTypes.bool,
        onClick: PropTypes.func,
        label: PropTypes.string,
        error: PropTypes.string,
        field: PropTypes.shape({
            onChange: PropTypes.func,
            validate: PropTypes.func,
            reset: PropTypes.func,
            id: PropTypes.string,
            value: PropTypes.string,
            files: PropTypes.arrayOf(PropTypes.object),
            placeholder: PropTypes.string,
            label: PropTypes.string,
            error: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {
        validateOnBlur: false,
        validateOnChange: false,
        onClick: null,
        label: null,
        error: null,
    };

    render() {
        const {
            field,
            children,
            validateOnBlur,
            validateOnChange,
            onClick,
            label,
            error,
        } = this.props;

        const handleBlur = children.props.onBlur && typeof children.props.onBlur === 'function'
            ? children.props.onBlur
            : () => {};

        const handleChange = (e) => {
            field.onChange(e);
            if (children.props.onChange) children.props.onChange(e);
        };

        const handleClick = () => {
            if (onClick) onClick();
            if (children.props.onClick && typeof children.props.onClick === 'function') {
                children.props.onClick();
            }
        };
        const onBlur = validateOnBlur
            ? (e) => { field.validate(); handleBlur(e); }
            : handleBlur;

        const onChange = validateOnChange
            ? (e) => { handleChange(e); field.validate(); }
            : handleChange;

        const reset = () => { field.reset(); };

        return React.cloneElement(children, {
            onBlur,
            onChange,
            onClick: handleClick,
            reset,
            id: field.id,
            files: field.files,
            value: field.value,
            placeholder: field.placeholder,
            label: label || field.label,
            error: error || field.error,
        });
    }
}

export default FormField;
