import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormField from '../FormField';
import { TextField } from '../Field';
import { BlueButton } from '../Button';

import styles from './EditQuestion.scss';

@observer
class Form extends Component {
    static propTypes = {
        form: PropTypes.shape({
            onSubmit: PropTypes.func,
            $: PropTypes.func,
            loading: PropTypes.bool,
        }).isRequired,
    };

    render() {
        const { props: { form } } = this;
        return (
            <form onSubmit={form.onSubmit}>
                <div>
                    <FormField field={form.$('question')} validateOnBlur validateOnChange>
                        <TextField id="question" />
                    </FormField>
                </div>
                <BlueButton
                    className={styles['edit-question__submit']}
                    disabled={form.loading}
                    type="submit"
                    text="Сохранить"
                />
            </form>
        );
    }
}

export default Form;
