/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

class FormContainer extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        contentClass: PropTypes.string,
    };

    static defaultProps = {
        contentClass: '',
    };

    render() {
        const { props } = this;
        const {
            title,
            contentClass,
        } = this.props;
        return (
            <div
                className={`
                    ${styles.content}
                    ${contentClass ? styles[`${contentClass}`] : ''}
                `}
            >
                <div
                    className={styles.contentInner}
                >
                    <h1>
                        {title}
                    </h1>
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default FormContainer;
