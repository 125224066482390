import { observable, computed, action } from 'mobx';
import HistoryItem from './HistoryItemModel';

class HistoryList {
    @observable _items = [];

    @observable _loading = false;

    @observable _deleting = false;

    constructor(root) {
        this.root = root;
    }

    @computed
    get items() {
        return this._items;
    }

    set items(data) {
        this._items = [];
        data.forEach((item) => {
            this._items.push(new HistoryItem(this.root, item));
        });
    }

    @computed
    get loading() {
        return this._loading;
    }

    set loading(status) {
        this._loading = status;
    }

    @computed
    get deleting() {
        return this._deleting;
    }

    set deleting(status) {
        this._deleting = status;
    }

    loadHistory() {
        const { root: { userService }, loading } = this;
        if (loading) return Promise.resolve();
        this.loading = true;
        return userService.getHistory()
            .then((history) => {
                if (!history || !history.length) {
                    return Promise.resolve();
                }
                this.items = history;
                return Promise.resolve();
            })
            .catch(() => {})
            .finally(() => {
                this.loading = false;
            });
    }

    @action
    removeVoting(index) {
        this._items.splice(index, 1);
    }

    deleteVoting(id) {
        const { root: { userService } } = this;
        this.deleting = true;
        return userService.deleteVoting(id)
            .then(() => {
                const index = this.items.findIndex(item => item.id === id);
                this.removeVoting(index);
            })
            .catch(() => {})
            .finally(() => {
                this.deleting = false;
            });
    }
}

export default HistoryList;
