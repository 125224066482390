import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Menu extends Component {
    static propTypes = {
        width: PropTypes.string,
        height: PropTypes.string,
        fill: PropTypes.string,
    }

    static defaultProps = {
        width: '19px',
        height: '17px',
        fill: '#fff',
    }

    render() {
        const {
            width,
            height,
            fill,
        } = this.props;
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 19 17"
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="0.708984" y="0.357666" width="17.5275" height="2.58701" rx="1" />
                <rect x="0.708984" y="7.06836" width="11.4966" height="2.58701" rx="1" />
                <rect x="0.708984" y="13.7791" width="6.33967" height="2.58701" rx="1" />
            </svg>
        );
    }
}

export default Menu;
