import Form from '../Form/FormModel';
import lastId from '../../utils/last-id';

class NewQuestion extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [{
                name: 'question',
                label: 'Вопрос бота',
                placeholder: '',
                rules: 'required|min:1|max:255',
                value: '',
                id: lastId(),
            }],
        };
    }
}

export default NewQuestion;
