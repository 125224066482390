import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ErrorDialog } from '../Dialog';

@inject('userStore', 'dialogStore')
@observer
class UserManager extends Component {
    @observable error = undefined;

    events = [];

    static propTypes = {
        userStore: PropTypes.shape({
            on: PropTypes.func,
        }).isRequired,
        dialogStore: PropTypes.shape({
            show: PropTypes.func,
            hide: PropTypes.func,
        }).isRequired,
    };

    componentDidMount() {
        const { props: { userStore } } = this;
        this.events = [
            userStore.on('user:error', this.handleError),
        ];
    }

    componentWillUnmount() {
        this.events.forEach((unsubscribe) => {
            unsubscribe();
        });
    }

    @computed
    get errorButton() {
        const { error } = this;
        if (!error) return null;
        switch (error.type) {
            default:
                return 'Закрыть';
        }
    }

    @computed
    get onErrorButtonClick() {
        const { error } = this;
        if (!error) return null;
        switch (error.type) {
            default:
                return this.hideDialog;
        }
    }

    @action
    handleError = (error) => {
        if (!error) return;
        this.error = error;
        this.openErrorDialog();
    }

    openDialog = (name) => {
        if (!name) return;
        const { props: { dialogStore } } = this;
        dialogStore.show(name);
    }

    hideDialog = () => {
        const { props: { dialogStore } } = this;
        dialogStore.hide();
    }

    openErrorDialog = () => {
        this.openDialog('user-error');
    }

    render() {
        const {
            error,
            onErrorButtonClick,
            errorButton,
        } = this;

        return (
            <React.Fragment>
                <ErrorDialog
                    name="user-error"
                    error={error}
                    onClick={onErrorButtonClick}
                    button={errorButton}
                />
            </React.Fragment>
        );
    }
}

export default UserManager;
