import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormField from '../FormField';
import { TextField } from '../Field';
import Preloader from '../Preloader';
import { RedButton } from '../Button';
import FormContainer from '../FormContainer';

@observer
class Form extends Component {
    static propTypes = {
        form: PropTypes.shape({
            onSubmit: PropTypes.func,
            $: PropTypes.func,
            loading: PropTypes.bool,
        }).isRequired,
    };

    render() {
        const { props: { form } } = this;
        return (
            <FormContainer title="Авторизация">
                <form onSubmit={form.onSubmit}>
                    <Preloader progress={form.loading} />
                    <div>
                        <FormField field={form.$('email')} validateOnBlur validateOnChange>
                            <TextField id="email" />
                        </FormField>
                        <FormField field={form.$('password')} validateOnBlur validateOnChange>
                            <TextField id="password" type="password" />
                        </FormField>
                    </div>
                    <RedButton
                        disabled={form.loading}
                        type="submit"
                        text="Войти"
                    />
                </form>
            </FormContainer>
        );
    }
}

export default Form;
