import UserService from '../../services/User';
import StorageService from '../../services/Storage';
import AppModel from '../App';
import UserModel from '../User/UserModel';
import DialogModel from '../Dialog/DialogModel';

class RootStore {
    constructor() {
        // Services
        const storageService = new StorageService();
        const userService = new UserService(storageService);

        this.storageService = storageService;
        this.userService = userService;

        // stores
        this.appStore = new AppModel(this);
        this.dialogStore = new DialogModel(this);
        this.userStore = new UserModel(this);
    }

    get stores() {
        const {
            appStore,
            dialogStore,
            userStore,
        } = this;
        return {
            appStore,
            dialogStore,
            userStore,
        };
    }
}

export default RootStore;
