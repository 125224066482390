import { observable, computed, action } from 'mobx';

const animationDuration = 2000;

class AppModel {
    @observable _menuIsOpen = true;

    @observable _menuIsOpening = false;

    @observable _firstOpening = false;

    @observable _filterIsOpen = true;

    @observable _fading = false;

    @computed
    get menuIsOpen() {
        return this._menuIsOpen;
    }

    @computed
    get filterIsOpen() {
        return this._filterIsOpen;
    }

    @computed
    get firstOpening() {
        return this._firstOpening;
    }

    @computed
    get menuIsOpening() {
        return this._menuIsOpening;
    }

    @computed
    get fading() {
        return this._fading;
    }

    @action
    setMenuIsOpen = (newState) => {
        this._menuIsOpen = newState;
    }

    @action
    setFilterIsOpen = (newState) => {
        this._filterIsOpen = newState;
    }

    @action
    setFirstOpening = (newState) => {
        this._firstOpening = newState;
    }

    @action
    setMenuIsOpening = (newState) => {
        this._menuIsOpening = newState;
    }

    @action
    setFadeOutInApp = (newState) => {
        this._fading = newState;
        setTimeout(() => {
            this._fading = false;
        }, animationDuration);
    }
}

export default AppModel;
