import { observable, computed } from 'mobx';
import Account from './AccountItemModel';

class AccountList {
    @observable _accounts = [];

    @observable _loading = false;

    constructor(root) {
        this.root = root;
    }

    @computed
    get accounts() {
        return this._accounts;
    }

    set accounts(data) {
        this._accounts = [];
        data.forEach((account) => {
            this._accounts.push(new Account(this.root, account));
        });
    }

    @computed
    get loading() {
        return this._loading;
    }

    set loading(status) {
        this._loading = status;
    }

    loadAccounts() {
        const { root: { userService }, loading } = this;
        if (loading) return Promise.resolve();
        this.loading = true;
        return userService.getAccounts()
            .then((accounts) => {
                if (!accounts || !accounts.length) {
                    return Promise.resolve();
                }
                this.accounts = accounts;
                return Promise.resolve();
            })
            .catch(() => {})
            .finally(() => {
                this.loading = false;
            });
    }
}

export default AccountList;
