import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Filter extends Component {
    static propTypes = {
        width: PropTypes.string,
        height: PropTypes.string,
        fill: PropTypes.string,
    }

    static defaultProps = {
        width: '19px',
        height: '19px',
        fill: '#fff',
    }

    render() {
        const {
            width,
            height,
            fill,
        } = this.props;
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 19 19"
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1.37145 0.170502H17.2114C17.3998 0.170619 17.5843 0.223926 17.7437 0.324284C17.903 0.424642 18.0308 0.567971 18.1123 0.73777C18.1938 0.907569 18.2257 1.09693 18.2044 1.28406C18.183 1.47119 18.1092 1.64847 17.9914 1.7955L11.5114 9.8965C11.3692 10.0738 11.2916 10.2942 11.2914 10.5215V15.6705C11.2914 15.8257 11.2553 15.9789 11.1859 16.1177C11.1164 16.2566 11.0156 16.3774 10.8914 16.4705L8.89145 17.9705C8.74288 18.0819 8.56621 18.1498 8.38125 18.1665C8.19629 18.1831 8.01034 18.148 7.84423 18.0649C7.67813 17.9819 7.53843 17.8542 7.4408 17.6962C7.34316 17.5383 7.29145 17.3562 7.29145 17.1705V10.5205C7.29104 10.2936 7.21346 10.0735 7.07145 9.8965L0.591446 1.7955C0.473729 1.64847 0.399929 1.47119 0.37854 1.28406C0.357151 1.09693 0.389044 0.907569 0.470548 0.73777C0.552051 0.567971 0.679851 0.424642 0.839233 0.324284C0.998615 0.223926 1.1831 0.170619 1.37145 0.170502Z" />
            </svg>
        );
    }
}

export default Filter;
