/* global document */
/* global localStorage */

const TYPE = {
    COOKIE: 0,
    LOCAL_STORAGE: 1,
    SESSION_STORAGE: 2,
};

class StorageService {
    constructor() {
        try {
            localStorage.setItem('test', 'test');
            localStorage.removeItem('test');
            this.type = TYPE.LOCAL_STORAGE;
        } catch (err) {
            this.type = TYPE.COOKIE;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    key(...args) {
        if (!args.length || args.filter(arg => typeof arg !== 'string').length) {
            return null;
        }
        return args.join('-');
    }

    get(...rest) {
        switch (this.type) {
            case TYPE.COOKIE:
                return StorageService._getItemFromCookie(...rest);
            case TYPE.LOCAL_STORAGE:
            default:
                return StorageService._getItemFromLocalStorage(...rest);
        }
    }

    save(...rest) {
        switch (this.type) {
            case TYPE.COOKIE:
                StorageService._setItemToCookie(...rest);
                break;
            case TYPE.LOCAL_STORAGE:
            default:
                StorageService._setItemToLocalStorage(...rest);
                break;
        }
    }

    remove(...rest) {
        switch (this.type) {
            case TYPE.COOKIE:
                StorageService._removeItemFromCookie(...rest);
                break;
            case TYPE.LOCAL_STORAGE:
            default:
                StorageService._removeItemFromLocalStorage(...rest);
                break;
        }
    }

    static _getItemFromCookie(name) {
        const matches = document.cookie.match(
            new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    static _getItemFromLocalStorage(name) {
        const data = localStorage.getItem(name);
        try {
            const response = JSON.parse(data);
            return response;
        } catch (e) {
            return data;
        }
    }

    static _setItemToCookie(name, value, options = {}) {
        const settings = Object.assign({}, options);
        let { expires } = settings;
        let updatedCookie = '';

        if (expires && typeof expires === 'number') {
            const date = new Date();
            date.setTime(date.getTime() + expires * 1000);
            expires = date;
            settings.expires = date;
        }
        if (expires && expires.toUTCString) {
            settings.expires = expires.toUTCString();
        }
        const encodedValue = encodeURIComponent(value);
        updatedCookie += `${name}=${encodedValue}`;

        const keys = Object.keys(settings);
        for (let i = 0; i < keys.length; i += 1) {
            const propName = keys[i];
            const propValue = settings[propName];
            updatedCookie += `; ${propName}`;
            if (propValue !== true) {
                updatedCookie += `=${propValue}`;
            }
        }
        document.cookie = updatedCookie;
    }

    static _setItemToLocalStorage(name, value) {
        const data = typeof value === 'string'
            ? value
            : JSON.stringify(value);
        localStorage.setItem(name, data);
    }

    static _removeItemFromCookie(name) {
        StorageService._setItemToCookie(name, '', {
            path: '/',
            expires: -1,
        });
    }

    static _removeItemFromLocalStorage(name) {
        localStorage.removeItem(name);
    }
}

export default StorageService;
