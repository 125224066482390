/* global window */
import React, { Component } from 'react';
import { Provider } from 'mobx-react';

// stores
import RootStore from './models/Root';

// base component
import Router from './components/Router';

// init stores
const { stores } = RootStore;

window.stores = stores;

class App extends Component {
    render() {
        return (
            <Provider {...stores}>
                <Router />
            </Provider>
        );
    }
}

export default App;
