import React from 'react';
import PageWrapper from '../../components/PageWrapper';
import SideBar from '../../components/SideBarContent/Questions';
import Question from '../../components/Question/Question';

import defaultStyles from '../../styles/default.scss';


class Questions extends React.Component {
    render() {
        return (
            <PageWrapper sideBarContent={SideBar}>
                <div className={defaultStyles.pageContainer}>
                    <Question />
                </div>
            </PageWrapper>
        );
    }
}

export default Questions;
