import Form from '../Form/FormModel';
import lastId from '../../utils/last-id';

class LoginForm extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [{
                name: 'email',
                label: 'E-mail',
                placeholder: 'user@example.com',
                rules: 'required|email|string',
                value: '',
                id: lastId(),
            }, {
                name: 'password',
                label: 'Пароль',
                rules: 'required|string',
                id: lastId(),
            }],
        };
    }
}

export default LoginForm;
