import { observable, computed, action } from 'mobx';
import Question from './QuestionItemModel';

class QuestionList {
    @observable _questions = [];

    @observable _loading = false;

    @observable _current = null;

    @observable _deleting = null;

    constructor(root) {
        this.root = root;
    }

    @computed
    get questions() {
        return this._questions;
    }

    set questions(data) {
        this._questions = [];
        data.forEach((question) => {
            this._questions.push(new Question(this.root, question));
        });
        this.selected = data[0].id;
    }

    @computed
    get loading() {
        return this._loading;
    }

    set loading(status) {
        this._loading = status;
    }

    @computed
    get deleting() {
        return this._deleting;
    }

    set deleting(status) {
        this._deleting = status;
    }

    @computed
    get selected() {
        return this.options[this._current];
    }

    set selected(id) {
        const index = this.questions.findIndex(item => item.id === id);
        this._current = index;
    }

    @computed
    get options() {
        return this.questions.map(item => ({
            value: item.id,
            label: `${item.id}. ${item.text}`,
        }));
    }

    @computed
    get current() {
        const { _current } = this;
        const question = this.questions[_current];
        return question || {};
    }

    loadQuestions() {
        const { root: { userService }, loading } = this;
        if (loading) return Promise.resolve();
        this.loading = true;
        return userService.getQuestions()
            .then((questions) => {
                if (!questions || !questions.length) {
                    return Promise.resolve();
                }
                this.questions = questions;
                return Promise.resolve();
            })
            .catch(() => {})
            .finally(() => {
                this.loading = false;
            });
    }

    @action
    setCurrent(selected) {
        this.selected = selected;
    }

    @action
    addQuestion(question) {
        const { root } = this;
        return this._questions.push(new Question(root, question));
    }

    @action
    removeQuestion(index) {
        return this._questions.splice(index, 1);
    }

    create({ question }) {
        const { root: { userService } } = this;
        return userService.addQuestion(question)
            .then((response) => {
                this.addQuestion(response);
                this.setCurrent(response.id);
                return Promise.resolve();
            })
            .catch(() => {});
    }

    delete() {
        const { root: { userService }, current: { id } } = this;
        this.deleting = true;
        return userService.deleteQuestion(id)
            .then(() => {
                const deletedIndex = this.questions.findIndex(item => item.id === id);
                const next = this.questions.find(item => item.id !== id);
                if (next) {
                    this.selected = next.id;
                } else {
                    this.selected = null;
                }
                this.removeQuestion(deletedIndex, 1);
            })
            .catch(() => {})
            .finally(() => {
                this.deleting = false;
            });
    }
}

export default QuestionList;
