/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormField from '../FormField';
import { TextField, ImageField } from '../Field';
import { BlueButton } from '../Button';

import styles from './EditAnswer.scss';

@observer
class Form extends Component {
    static propTypes = {
        form: PropTypes.shape({
            onSubmit: PropTypes.func,
            $: PropTypes.func,
            loading: PropTypes.bool,
            values: PropTypes.func,
        }).isRequired,
    };

    handleImageChange = (e) => {
        const { props: { form } } = this;
        form.$('image').set('value', e.target.value);
        form.$('reaction').validate();
    }

    handleReactionChange = () => {
        const { props: { form } } = this;
        form.$('image').validate();
    }

    resetOldImage = () => {
        const { props: { form } } = this;
        form.$('oldImage').set('value', null);
    }

    render() {
        const {
            props: { form },
            handleImageChange,
            handleReactionChange,
            resetOldImage,
        } = this;
        window.editForm = form;
        return (
            <form onSubmit={form.onSubmit}>
                <div>
                    <FormField field={form.$('answer')} validateOnBlur validateOnChange>
                        <TextField id="answer" />
                    </FormField>
                    <FormField field={form.$('reaction')} validateOnBlur validateOnChange>
                        <TextField id="reaction" onChange={handleReactionChange} />
                    </FormField>
                    {
                        form.$('oldImage').value
                            ? (
                                <div className={styles.loaded}>
                                    <img
                                        className={styles.loaded__image}
                                        src={form.$('oldImage').value}
                                        alt="loaded"
                                    />
                                    <div className={styles.loaded__name}>
                                        old image
                                    </div>
                                    <button
                                        type="button"
                                        className={styles.loaded__reset}
                                        onClick={resetOldImage}
                                    />
                                </div>
                            )
                            : (
                                <FormField field={form.$('image')} validateOnBlur validateOnChange>
                                    <ImageField id="image" onChange={handleImageChange} />
                                </FormField>
                            )
                    }
                </div>
                <BlueButton
                    className={styles['new-question__submit']}
                    disabled={form.loading}
                    type="submit"
                    text="Сохранить"
                />
            </form>
        );
    }
}

export default Form;
