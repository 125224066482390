import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PreloaderIcon extends Component {
    static propTypes = {
        width: PropTypes.string,
        height: PropTypes.string,
        fillCircle: PropTypes.string,
        fillSector: PropTypes.string,
    };

    static defaultProps = {
        width: '34px',
        height: '34px ',
        fillCircle: '#ef3125',
        fillSector: '#9c0900',
    };

    render() {
        const {
            width,
            height,
            fillCircle,
            fillSector,
        } = this.props;
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17ZM9.71655 17C9.71655 21.0225 12.9775 24.2835 17 24.2835C21.0225 24.2835 24.2835 21.0225 24.2835 17C24.2835 12.9775 21.0225 9.71655 17 9.71655C12.9775 9.71655 9.71655 12.9775 9.71655 17Z"
                    fill={fillCircle}
                />
                <path
                    d="M33.6824 13.7293C33.202 11.2789 32.1876 8.96442 30.7115 6.95042C29.2354 4.93642 27.3337 3.27232 25.1417 2.07644C22.9497 0.88055 20.5211 0.182241 18.0286 0.0311494C15.5362 -0.119942 13.0411 0.279895 10.7206 1.20224L14.3097 10.2316C15.3038 9.83647 16.3728 9.66516 17.4407 9.72989C18.5086 9.79463 19.5491 10.0938 20.4882 10.6062C21.4274 11.1185 22.2421 11.8315 22.8745 12.6944C23.507 13.5573 23.9415 14.5489 24.1474 15.5987L33.6824 13.7293Z"
                    fill={fillSector}
                />
            </svg>
        );
    }
}

export default PreloaderIcon;
