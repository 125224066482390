import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import Dialog from './Dialog';

import styles from './Dialog.scss';
import { BlueButton } from '../Button';

@inject('dialogStore')
class ErrorDialog extends Component {
    static propTypes = {
        dialogStore: PropTypes.shape({}).isRequired,
        header: PropTypes.string,
        closeable: PropTypes.bool,
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        error: PropTypes.string,
        button: PropTypes.string,
        onClick: PropTypes.func,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.element),
        ]),
    };

    static defaultProps = {
        header: null,
        closeable: true,
        className: '',
        error: undefined,
        button: '',
        onClick: null,
        children: null,
    };

    render() {
        const {
            props: {
                header,
                name,
                closeable,
                className,
                button,
                onClick,
                error,
                children,
            },
        } = this;
        return (
            <Dialog
                closeable={closeable}
                header={header || 'Ошибка'}
                name={name}
                className={`${className} ${styles['dialog--error']}`}
                size="md"
                footer={null}
                history={false}
            >
                <div className={styles.error}>
                    <div className={styles.error__message}>
                        {error}
                    </div>
                    {
                        button
                            ? (
                                <div className={styles.error__back}>
                                    <BlueButton
                                        type="button"
                                        text={button}
                                        onClick={onClick}
                                    />
                                </div>
                            ) : null
                    }
                    {children}
                </div>
            </Dialog>
        );
    }
}

export default ErrorDialog;
