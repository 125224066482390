import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import styles from './styles.scss';

@observer
class BaseSideBarContent extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    };

    static defaultProps = {
        children: null,
    };

    render() {
        const { props: { children } } = this;
        return (
            <div className={styles.wrapper}>
                {children}
            </div>
        );
    }
}

export default BaseSideBarContent;
