import request from 'request-promise-native';
import { BE_HOST } from '../constants/constants';
import RequestError from '../errors/request-error';

class Request {
    request = new Proxy(request, {
        get(target, name, receiver) {
            if (typeof target[name] !== 'function') {
                return Reflect.get(target, name, receiver);
            }
            return (path, options = {}) => {
                if (typeof path !== 'string') {
                    throw new Error('path must be a string');
                }
                if (options && typeof options !== 'object') {
                    throw new Error('options must be an object');
                }
                const url = `${BE_HOST}/${path.replace(/^\//, '')}`;
                return Reflect.get(target, name, receiver)(url, {
                    ...options,
                    headers: {
                        ...options.headers,
                        'Accept-Language': 'ru',
                    },
                })
                    .then((body) => {
                        try {
                            const data = JSON.parse(body);
                            return data;
                        } catch (e) {
                            return body;
                        }
                    })
                    .catch((err) => {
                        const error = new RequestError(err);
                        return Promise.reject(error);
                    });
            };
        },
    });
}

export default Request;
