import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import styles from './styles.scss';

class BlueButton extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    render() {
        const { props, props: { className } } = this;
        return (
            <Button
                {...props}
                className={`${className} ${styles.buttonBlue}`}
            />
        );
    }
}

export default BlueButton;
