import React from 'react';
import PageWrapper from '../../components/PageWrapper';
import SideBar from '../../components/SideBarContent/History';
import History from '../../components/History';

import defaultStyles from '../../styles/default.scss';


class Questions extends React.Component {
    render() {
        return (
            <PageWrapper sideBarContent={SideBar}>
                <div className={defaultStyles.pageContainer}>
                    <History />
                </div>
            </PageWrapper>
        );
    }
}

export default Questions;
