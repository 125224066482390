import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';
import ReactHintFactory from 'react-hint';
import isSafari from '../../utils/browser-type';
import Login from '../../pages/Login';
import Authorized from '../../pages/Authorized';
import UserManager from '../UserManager';

import styles from './Router.scss';

const ReactHint = ReactHintFactory(React);

class AppRouter extends React.Component {
    render() {
        return (
            <div
                className={`
                    ${styles.App}
                    ${isSafari() === true ? 'App--safari' : ''}
                `}
            >
                <UserManager />
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <BrowserRouter>
                            <React.Fragment>
                                <Switch>
                                    <Route path="/login" component={Login} />
                                    <Route path="/" component={Authorized} />
                                </Switch>
                            </React.Fragment>
                        </BrowserRouter>
                    </div>
                    <ReactHint
                        position="top"
                        events={{ click: true, hover: true }}
                        delay={100}
                    />
                </div>
            </div>
        );
    }
}

export default AppRouter;
