import { observable, computed } from 'mobx';

class Answer {
    @observable _id;

    @observable _text;

    @observable _reaction;

    @observable _attachment;

    questionId;

    constructor(root, questionId, {
        id,
        text,
        reaction,
        attachment_url: attachment,
    }) {
        this.root = root;
        this.questionId = questionId;
        this.id = id;
        this.text = text;
        this.reaction = reaction;
        this.attachment = attachment;
    }

    @computed
    get text() {
        return this._text || '';
    }

    set text(text) {
        this._text = text;
    }

    @computed
    get id() {
        return this._id || null;
    }

    set id(id) {
        this._id = id;
    }

    @computed
    get reaction() {
        return this._reaction || '';
    }

    set reaction(reaction) {
        this._reaction = reaction;
    }

    @computed
    get attachment() {
        return this._attachment || null;
    }

    set attachment(attachment) {
        this._attachment = attachment;
    }

    edit({
        answer,
        reaction,
        image,
        oldImage,
    }) {
        const { root: { userService }, id, questionId } = this;
        return userService.editAnswer(
            questionId,
            id,
            answer,
            reaction,
            image,
            oldImage,
        )
            .then((response) => {
                const { text, reaction: newReaction, attachment_url: attachment } = response;
                this.text = text;
                this.reaction = newReaction;
                this.attachment = attachment;
            })
            .catch(() => {});
    }
}

export default Answer;
