import { observable, computed } from 'mobx';

class HistoryItem {
    @observable _id;

    @observable _date;

    @observable _question;

    @observable _respondents;

    @observable _results = [];

    constructor(root, {
        id,
        date,
        question: { text: question },
        respondents: total,
        results,
    }) {
        this.root = root;
        this.id = id;
        this.date = date;
        this.question = question;
        this.total = total;
        this.results = results;
    }

    @computed
    get id() {
        return this._id || null;
    }

    set id(id) {
        this._id = id;
    }

    @computed
    get date() {
        return this._date || '';
    }

    set date(date) {
        this._date = date;
    }

    @computed
    get question() {
        return this._question || '';
    }

    set question(question) {
        this._question = question;
    }

    @computed
    get total() {
        return this._respondents || 0;
    }

    set total(total) {
        this._respondents = total;
    }

    @computed
    get results() {
        return this._results || [];
    }

    set results(results) {
        this._results = results;
    }
}

export default HistoryItem;
