import { observable, computed } from 'mobx';

class Account {
    @observable _id;

    @observable _name;

    @observable _username = [];

    @observable _role;

    constructor(root, {
        id,
        name,
        username,
        role,
    }) {
        this.root = root;
        this.id = id;
        this.name = name;
        this.username = username;
        this.role = role;
    }

    @computed
    get id() {
        return this._id || null;
    }

    set id(id) {
        this._id = id;
    }

    @computed
    get name() {
        return this._name || '';
    }

    set name(name) {
        this._name = name;
    }

    @computed
    get username() {
        return this._username || this._name || '-/-';
    }

    set username(username) {
        this._username = username;
    }

    @computed
    get role() {
        return this.isAdmin
            ? 'Администратор'
            : '-';
    }

    set role(role) {
        this._role = role;
    }

    @computed
    get isAdmin() {
        return this._role === 'admin';
    }

    togglePrivileges() {
        const { root: { userService }, isAdmin, id } = this;
        const nextState = isAdmin ? 'user' : 'admin';
        return userService.toggleAccountPrivileges(id, isAdmin ? 0 : 1)
            .then(() => {
                this.role = nextState;
            })
            .catch(() => {});
    }
}

export default Account;
