import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import AccountModel from '../../models/Account/AccountItemModel';
import { WhiteButton } from '../Button';
import Dialog from '../Dialog';

import styles from './Accounts.scss';

@inject('dialogStore')
@observer
class Account extends React.Component {
    static propTypes = {
        account: PropTypes.instanceOf(AccountModel).isRequired,
        dialogStore: PropTypes.shape({
            show: PropTypes.func,
            hide: PropTypes.func,
        }).isRequired,
    };

    togglePrivileges = () => {
        const { props: { account } } = this;
        account.togglePrivileges()
            .then(this.cancel.bind(this));
    }

    confirm = () => {
        const { props: { dialogStore, account: { id } } } = this;
        return dialogStore.show(`toggle-admin-${id}`);
    }

    cancel = () => {
        const { props: { dialogStore } } = this;
        return dialogStore.hide();
    }

    render() {
        const {
            props: {
                account: {
                    role,
                    username,
                    id,
                    isAdmin,
                    toggling,
                },
            },
            cancel,
            confirm,
            togglePrivileges,
        } = this;
        return (
            <React.Fragment>
                <tr>
                    <td>{username}</td>
                    <td>{id}</td>
                    <td>{role}</td>
                    <td>
                        <WhiteButton
                            className={styles.accounts__action}
                            type="button"
                            text={isAdmin ? 'Убрать из администраторов' : 'Сделать администратором'}
                            onClick={confirm}
                        />
                    </td>
                </tr>
                <Dialog
                    name={`toggle-admin-${id}`}
                    header="Подтверждение"
                >
                    <div className={styles.delete}>
                        <div className={styles.delete__message}>
                            Вы точно хотите изменить набор прав для этого пользователя?
                        </div>
                        <div className={styles.delete__footer}>
                            <WhiteButton
                                className={styles.delete__action}
                                type="button"
                                disabled={toggling}
                                text="Да"
                                onClick={togglePrivileges}
                            />
                            <WhiteButton
                                className={styles.delete__action}
                                type="button"
                                text="Нет"
                                onClick={cancel}
                            />
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default Account;
