import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

@inject('userStore')
@observer
class RequiredAuthorization extends React.Component {
    static propTypes = {
        userStore: PropTypes.shape({
            isAuthorized: PropTypes.bool,
        }).isRequired,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string,
        ]),
    };

    static defaultProps = {
        children: null,
    };

    render() {
        const { props: { userStore: { isAuthorized }, children } } = this;
        return isAuthorized === false
            ? <Redirect push to="/login" />
            : (
                <React.Fragment>
                    {children}
                </React.Fragment>
            );
    }
}

export default RequiredAuthorization;
