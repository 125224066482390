import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import Login from '../../components/Login';

@inject('userStore')
@observer
class LoginPage extends React.Component {
    static propTypes = {
        userStore: PropTypes.shape({
            isAuthorized: PropTypes.bool,
        }).isRequired,
    };

    render() {
        const { props: { userStore: { isAuthorized } } } = this;
        return isAuthorized
            ? <Redirect push to="/questions" />
            : <Login />;
    }
}

export default LoginPage;
