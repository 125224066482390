import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Input extends Component {
    static propTypes = {
        autoComplete: PropTypes.oneOf(['on', 'off']),
        type: PropTypes.oneOf(['text', 'password', 'checkbox', 'radio', 'number', 'file']),
        name: PropTypes.string,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        checked: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.string.isRequired,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        autoComplete: 'off',
        type: 'text',
        disabled: false,
        readOnly: false,
        checked: undefined,
        value: '',
        className: '',
        name: undefined,
        placeholder: '',
        onFocus: null,
        onChange: null,
        onBlur: null,
        onClick: null,
    }

    render() {
        const {
            autoComplete,
            type,
            readOnly,
            disabled,
            checked,
            value,
            id,
            className,
            name,
            placeholder,
            onFocus,
            onChange,
            onBlur,
            onClick,
        } = this.props;
        return (
            <input
                autoComplete={autoComplete}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                onClick={onClick}
                id={id}
                type={type}
                name={name}
                className={className}
                disabled={disabled}
                readOnly={readOnly}
                placeholder={placeholder}
                checked={checked}
                value={value}
            />
        );
    }
}

export default Input;
