const defaults = {
    value: null,
    expiresIn: 3600,
    expiresAt: Date.now() + 3600 * 1000,
};

class Token {
    options = {};

    set token(options) {
        if (!(options instanceof Object) && !(typeof options === 'string')) return;
        if (options.expiresAt && Date.now() >= options.expiresAt) return;
        if (typeof options === 'string') {
            this.options = { ...defaults, value: options };
        } else {
            const {
                value,
                expiresIn,
                expiresAt,
            } = options;
            const { expiresIn: defaultExpiresIn } = defaults;
            this.options = {
                value,
                expiresIn: expiresIn || defaultExpiresIn,
                expiresAt: expiresAt || Date.now() + (expiresIn || defaultExpiresIn) * (10 ** 3),
            };
        }
    }

    get token() {
        const { options: { value } } = this;
        return value ? `${value}` : null;
    }

    reset() {
        const { options } = this;
        options.value = null;
    }
}

export default Token;
