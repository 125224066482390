import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

class Button extends Component {
    static propTypes = {
        type: PropTypes.oneOf(['submit', 'button', 'reset']),
        text: PropTypes.string.isRequired,
        className: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        type: 'button',
        className: '',
        disabled: false,
        onClick: null,
    };

    onClick = (evt) => {
        const {
            onClick,
        } = this.props;
        if (onClick) onClick(evt);
    }

    render() {
        const {
            type,
            text,
            className,
            disabled,
        } = this.props;
        return (
            <div>
                {/* eslint-disable-next-line react/button-has-type */}
                <button
                    type={type}
                    className={`${styles.buttonDefault} ${className}`}
                    onClick={this.onClick}
                    disabled={disabled}
                >
                    {text}
                </button>
            </div>
        );
    }
}

export default Button;
