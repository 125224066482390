import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Arrow extends Component {
    static propTypes = {
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
    };

    static defaultProps = {
        fill: '#000',
        width: '10px',
        height: '12px',
    };

    render() {
        const {
            fill,
            width,
            height,
        } = this.props;
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 10 8"
                fill="none"
            >
                <path
                    d="M10 2.08329L8.83333 0.916626L5 4.74996L1.16667 0.916626L-9.67587e-07 2.08329L5 7.08329L10 2.08329Z"
                    fill={fill}
                />
            </svg>
        );
    }
}

export default Arrow;
