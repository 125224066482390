import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import HistoryItemModel from '../../models/History/HistoryItemModel';
import { WhiteButton } from '../Button';

import styles from './History.scss';
import Dialog from '../Dialog';

@inject('dialogStore')
@observer
class HistoryItem extends React.Component {
    static propTypes = {
        item: PropTypes.instanceOf(HistoryItemModel).isRequired,
        deleting: PropTypes.bool,
        deleteVoting: PropTypes.func,
        dialogStore: PropTypes.shape({
            show: PropTypes.func,
            hide: PropTypes.func,
        }).isRequired,
    };

    static defaultProps = {
        deleting: false,
        deleteVoting: null,
    };

    confirm = () => {
        const { props: { dialogStore, item: { id } } } = this;
        return dialogStore.show(`delete-item-${id}`);
    }

    cancel = () => {
        const { props: { dialogStore } } = this;
        return dialogStore.hide();
    }

    deleteVoting = () => {
        const { props: { deleteVoting, item: { id } } } = this;
        deleteVoting(id);
    }

    render() {
        const {
            props: {
                item: {
                    id,
                    question,
                    date,
                    results,
                    total,
                },
                deleting,
            },
            confirm,
            cancel,
            deleteVoting,
        } = this;
        return (
            <React.Fragment>
                <tr>
                    <td>{date}</td>
                    <td>{question}</td>
                    <td>
                        <div className={styles.results}>
                            <div className={styles.results__more}>
                                {results.map(({ percent, text }) => (
                                    <div key={`history-results-${id}-${text}`}>
                                        <b data-rh={`${Math.round(total / 100 * percent)} человек`}>{`${percent}%`}</b>
                                        &nbsp;
                                        {text}
                                    </div>
                                ))}
                            </div>
                            <div className={styles.results__total}>
                                Респондентов:
                                &nbsp;
                                {total}
                            </div>
                        </div>
                    </td>
                    <td>
                        <WhiteButton
                            className={styles.history__action}
                            type="button"
                            text="Удалить"
                            onClick={confirm}
                        />
                    </td>
                </tr>
                <Dialog
                    name={`delete-item-${id}`}
                    header="Подтверждение"
                >
                    <div className={styles.delete}>
                        <div className={styles.delete__message}>
                            Вы точно хотите удалить это голосование из истории?
                        </div>
                        <div className={styles.delete__footer}>
                            <WhiteButton
                                className={styles.delete__action}
                                type="button"
                                disabled={deleting}
                                text="Да"
                                onClick={deleteVoting}
                            />
                            <WhiteButton
                                className={styles.delete__action}
                                type="button"
                                text="Нет"
                                onClick={cancel}
                            />
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default HistoryItem;
