class RequestError extends Error {
    constructor(unhandledError) {
        const {
            response,
            message: basicMessage,
            statusCode,
            error,
        } = unhandledError;
        const parsedError = RequestError._parseError(error);
        super(parsedError.message || basicMessage);
        this.response = response;
        this.code = parsedError.code || statusCode;
        this.details = parsedError.details;
        this.name = 'RequestError';
        this.statusCode = statusCode;
    }

    static _parseError(error) {
        let parsedError;
        try {
            parsedError = JSON.parse(error);
        } catch (e) {
            return {
                message: parsedError,
            };
        }
        return {
            code: parsedError.details
                ? parsedError.details.status || parsedError.code
                : parsedError.code,
            details: parsedError.details,
            message: parsedError.message,
        };
    }

    get type() {
        if (!this.response) return null;
        const {
            response: { request: { uri: { path } } },
            code,
        } = this;
        const prefix = path
            .slice(1)
            .replace(/\//g, '_')
            .toUpperCase();
        return `${prefix}_${code.toUpperCase()}`;
    }
}

export default RequestError;
