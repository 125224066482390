import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormField from '../FormField';
import { TextField, ImageField } from '../Field';
import { BlueButton } from '../Button';

import styles from './NewAnswer.scss';

@observer
class Form extends Component {
    static propTypes = {
        form: PropTypes.shape({
            onSubmit: PropTypes.func,
            $: PropTypes.func,
            loading: PropTypes.bool,
            values: PropTypes.func,
        }).isRequired,
    };

    handleImageChange = (e) => {
        const { props: { form } } = this;
        form.$('image').set('value', e.target.value);
        form.$('reaction').validate();
    }

    handleReactionChange = () => {
        const { props: { form } } = this;
        form.$('image').validate();
    }

    render() {
        const {
            props: { form },
            handleImageChange,
            handleReactionChange,
        } = this;
        return (
            <form onSubmit={form.onSubmit}>
                <div>
                    <FormField field={form.$('answer')} validateOnBlur validateOnChange>
                        <TextField id="answer" />
                    </FormField>
                    <FormField field={form.$('reaction')} validateOnBlur validateOnChange>
                        <TextField id="reaction" onChange={handleReactionChange} />
                    </FormField>
                    <FormField field={form.$('image')} validateOnBlur validateOnChange>
                        <ImageField id="image" onChange={handleImageChange} />
                    </FormField>
                </div>
                <BlueButton
                    className={styles['new-question__submit']}
                    disabled={form.loading}
                    type="submit"
                    text="Добавить"
                />
            </form>
        );
    }
}

export default Form;
