import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Questions from '../Questions';
import Accounts from '../Accounts';
import History from '../History';
import RequiredAuthorization from '../../components/RequiredAuthorization';

class Authorized extends React.Component {
    render() {
        return (
            <RequiredAuthorization>
                <React.Fragment>
                    <Switch>
                        <Route path="/questions" component={Questions} />
                        <Route path="/accounts" component={Accounts} />
                        <Route path="/history" component={History} />
                        <Redirect push from="/" to="/questions" />
                    </Switch>
                </React.Fragment>
            </RequiredAuthorization>
        );
    }
}

export default Authorized;
