import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Preloader from '../Preloader';
import Header from '../Header';
import { BlueButton, WhiteButton } from '../Button';
import Dialog from '../Dialog/Dialog';
import NewAnswerFormModel from '../../models/NewAnswerForm/NewAnswerForm';
import EditAnswerFormModel from '../../models/EditAnswerForm/EditAnswerForm';
import NewAnswer from '../NewAnswer';
import EditAnswer from '../EditAnswer';

import styles from './Question.scss';

@inject('userStore', 'dialogStore')
@observer
class Question extends React.Component {
    newForm = new NewAnswerFormModel({
        hooks: {
            onSuccess: (form) => {
                const { props: { userStore: { questions: { current } }, dialogStore } } = this;
                const { answer, reaction } = form.values();
                const { files } = form.$('image');
                const image = files && files.length ? files[0] : undefined;
                return current.createAnswer({
                    answer,
                    reaction,
                    image,
                })
                    .then(() => {
                        form.reset();
                        form.validate();
                        dialogStore.hide();
                    });
            },
        },
    })

    editForm = new EditAnswerFormModel({
        hooks: {
            onSuccess: (form) => {
                const {
                    props: {
                        userStore: { questions: { current: { answers } } },
                        dialogStore,
                    },
                } = this;
                const {
                    answer,
                    reaction,
                    oldImage,
                    id,
                } = form.values();
                const saved = answers.find(item => item.id === id);
                const { files } = form.$('image');
                const image = files && files.length ? files[0] : undefined;
                return saved.edit({
                    answer,
                    reaction,
                    image,
                    oldImage,
                })
                    .then(() => {
                        form.reset();
                        form.validate();
                        dialogStore.hide();
                    });
            },
        },
    })

    static propTypes = {
        userStore: PropTypes.shape({
            questions: PropTypes.shape({
                current: PropTypes.shape({
                    createAnswer: PropTypes.func,
                    deleteAnswer: PropTypes.func,
                    editAnswer: PropTypes.func,
                    setDeleteAnswerId: PropTypes.func,
                    deleting: PropTypes.bool,
                    answers: PropTypes.arrayOf(PropTypes.shape({
                        text: PropTypes.string,
                        id: PropTypes.number,
                    })),
                }),
                loading: PropTypes.bool,
            }),
        }).isRequired,
        dialogStore: PropTypes.shape({
            show: PropTypes.func,
            hide: PropTypes.func,
        }).isRequired,
    };

    openNewAnswerForm = () => {
        const { props: { dialogStore } } = this;
        return dialogStore.show('add-answer');
    }

    openEditAnswerForm = (id) => {
        const {
            props: {
                dialogStore,
                userStore: { questions: { current: { answers } } },
            },
            editForm,
        } = this;
        const { text, reaction, attachment } = answers.find(item => item.id === id);
        editForm.$('id').set('value', id);
        editForm.$('answer').set('value', text);
        editForm.$('reaction').set('value', reaction);
        editForm.$('oldImage').set('value', attachment);
        editForm.$('image').reset();
        return dialogStore.show('edit-answer');
    }

    openDeleteForm = (id) => {
        const { props: { userStore: { questions: { current } }, dialogStore } } = this;
        current.setDeleteAnswerId(id);
        return dialogStore.show('delete-answer');
    }

    deleteAnswer = () => {
        const { props: { userStore: { questions: { current } }, dialogStore } } = this;
        current.deleteAnswer()
            .then(() => {
                dialogStore.hide();
            });
    }

    hideDialog = () => {
        const { props: { dialogStore } } = this;
        return dialogStore.hide();
    }

    render() {
        const {
            props: {
                userStore: {
                    questions: { current: { answers = [], deleting }, loading },
                },
            },
            newForm: newAnswerForm,
            editForm: editAnswerForm,
            deleteAnswer,
            hideDialog,
            openNewAnswerForm,
            openEditAnswerForm,
            openDeleteForm,
        } = this;
        return (
            <React.Fragment>
                <div className={styles.question}>
                    <Preloader progress={loading} />
                    <Header title="Вопрос/ответ">
                        <BlueButton
                            className={styles.question__add}
                            type="button"
                            text="Добавить ответ"
                            onClick={openNewAnswerForm}
                        />
                    </Header>
                    <table className={styles.question__list}>
                        <tbody>
                            <tr>
                                <th>Ответ</th>
                                <th>Редактировать</th>
                                <th>Удалить</th>
                            </tr>
                            {
                                answers.map(({ text, id }) => (
                                    <tr key={`answer-${id}`}>
                                        <td>{text}</td>
                                        <td>
                                            <WhiteButton
                                                className={styles.question__action}
                                                type="button"
                                                text="Редактировать"
                                                onClick={() => openEditAnswerForm(id)}
                                            />
                                        </td>
                                        <td>
                                            <WhiteButton
                                                className={styles.question__action}
                                                type="button"
                                                text="Удалить"
                                                onClick={() => openDeleteForm(id)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <Dialog
                    name="add-answer"
                    header="Добавить ответ"
                >
                    <NewAnswer form={newAnswerForm} />
                </Dialog>
                <Dialog
                    name="edit-answer"
                    header="Редактировать ответ"
                >
                    <EditAnswer form={editAnswerForm} />
                </Dialog>
                <Dialog
                    name="delete-answer"
                    header="Подтверждение"
                >
                    <div className={styles.delete}>
                        <div className={styles.delete__message}>
                            Вы точно хотите удалить этот ответ?
                        </div>
                        <div className={styles.delete__footer}>
                            <WhiteButton
                                className={styles.delete__action}
                                type="button"
                                disabled={deleting}
                                text="Да"
                                onClick={deleteAnswer}
                            />
                            <WhiteButton
                                className={styles.delete__action}
                                type="button"
                                text="Нет"
                                onClick={hideDialog}
                            />
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default Question;
