import Form from '../Form/FormModel';
import lastId from '../../utils/last-id';

class NewAnswer extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [{
                name: 'answer',
                label: 'Ответ пользователя',
                placeholder: '',
                rules: 'required|min:1|max:255',
                value: '',
                id: lastId(),
            }, {
                name: 'reaction',
                label: 'Реакция бота',
                placeholder: '',
                rules: 'required_without:image|min:1|max:255',
                value: '',
                id: lastId(),
            }, {
                name: 'image',
                type: 'file',
                label: 'Прикрепить изображение',
                rules: 'required_without:reaction',
                value: '',
                id: lastId(),
            }],
        };
    }
}

export default NewAnswer;
