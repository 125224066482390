const BE_HOST = process.env.REACT_APP_BACKEND_PATH;
const AUTH_TOKEN_UPDATE_LAG = 10000;

export default {
    BE_HOST,
};

export {
    BE_HOST,
    AUTH_TOKEN_UPDATE_LAG,
};
