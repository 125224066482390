import React from 'react';
import PropTypes from 'prop-types';
import BaseSideBarContent from '..';

import styles from './Text.scss';

class TextSideBar extends React.Component {
    static propTypes = {
        text: PropTypes.string,
    };

    static defaultProps = {
        text: '',
    };

    render() {
        const {
            props: {
                text,
            },
        } = this;
        return (
            <BaseSideBarContent>
                <React.Fragment>
                    <div className={styles.text}>
                        {text}
                    </div>
                </React.Fragment>
            </BaseSideBarContent>
        );
    }
}

export default TextSideBar;
