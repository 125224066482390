import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PreloaderIcon from '../IconsSvg/Preloader';
import styles from './styles.scss';

class Preloader extends Component {
    static propTypes = {
        progress: PropTypes.bool.isRequired,
    }

    render() {
        const { progress } = this.props;
        return (
            <div>
                {
                    progress === true
                        ? (
                            <div
                                className={styles.box}
                            >
                                <div
                                    className={styles.boxInner}
                                >
                                    <div
                                        className={styles.preloader}
                                    >
                                        <div
                                            className={styles.preloaderInner}
                                        >
                                            <PreloaderIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default Preloader;
