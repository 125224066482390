/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import defaultStyles from '../../styles/default.scss';
import Arrow from '../IconsSvg/Arrow';

import styles from './Select.scss';

const DropdownIndicator = props => (
    components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <div
                className={`
                    ${styles.arrow}
                    ${props.selectProps.menuIsOpen ? styles.arrowUp : ''}
                `}
            >
                <Arrow fill="currentColor" />
            </div>
        </components.DropdownIndicator>
    )
);

class CustomSelect extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.number.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ).isRequired,
        value: PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        }),
        handleChange: PropTypes.func,
        disabled: PropTypes.bool,
        isSearchable: PropTypes.bool,
    };

    static defaultProps = {
        handleChange: null,
        value: null,
        disabled: false,
        isSearchable: false,
    };

    handleChange = (selectedOption) => {
        const { handleChange } = this.props;
        if (handleChange) handleChange(selectedOption);
    }

    render() {
        const {
            props: {
                error,
                label,
                showError,
                showLabel,
                value,
                options,
                disabled,
                isSearchable,
            },
            handleChange,
        } = this;
        return (
            <div
                className={`
                    ${styles.selectContainer}
                    ${error ? styles.selectContainerError : ''}
                `}
            >
                {
                    showLabel
                        ? (
                            <div className={styles.label}>
                                <span>
                                    {label}
                                </span>
                            </div>
                        )
                        : null
                }
                <Select
                    components={{ DropdownIndicator }}
                    value={value}
                    onChange={handleChange}
                    options={options}
                    classNamePrefix="react-select"
                    className={`
                        ${styles.select}
                        ${error ? styles.selectError : ''}
                        ${isSearchable ? styles['select--searchable'] : ''}
                    `}
                    isSearchable={isSearchable}
                    isDisabled={disabled}
                />
                {
                    showError
                        ? (
                            <div className={defaultStyles.inputErrorText}>
                                <span>
                                    {error}
                                </span>
                            </div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default CustomSelect;
