/* eslint-disable react/prop-types */
/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import MenuComponent from '../Menu';
import { WhiteButton } from '../Button';
import Menu from '../IconsSvg/Menu';
import Filter from '../IconsSvg/Filter';
import logo from '../../assets/images/logo.svg';

import styles from './styles.scss';

@inject('userStore', 'appStore')
@observer
class PageWrapper extends React.Component {
    static propTypes = {
        sideBarContent: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const windowWidth = window.innerWidth;
        if (windowWidth > 768) {
            this.desktopInitOpenMenu();
        } else {
            this.mobileInitOpenMenu();
        }
    }

    desktopInitOpenMenu = () => {
        const { props } = this;
        const { appStore } = props;
        if (appStore.firstOpening === false) {
            appStore.setMenuIsOpen(true);
            return;
        }
        setTimeout(() => {
            appStore.setMenuIsOpening(true);
            appStore.setFirstOpening(false);
        }, 500);
        setTimeout(() => {
            appStore.setMenuIsOpening(false);
            appStore.setMenuIsOpen(true);
        }, 1000);
    }

    mobileInitOpenMenu = () => {
        const { props } = this;
        const { appStore } = props;
        setTimeout(() => {
            appStore.setMenuIsOpen(false);
        }, 500);
        setTimeout(() => {
            appStore.setFilterIsOpen(false);
        }, 1000);
    }

    handleMenu = () => {
        const { props } = this;
        const { appStore } = props;
        const { filterIsOpen } = appStore;
        if (filterIsOpen === true) {
            appStore.setFilterIsOpen(false);
            appStore.setMenuIsOpen(!appStore.menuIsOpen);
            return;
        }
        appStore.setMenuIsOpen(!appStore.menuIsOpen);
    }

    handleFilter = () => {
        const { props } = this;
        const { appStore } = props;
        const { menuIsOpen } = appStore;
        if (menuIsOpen === true) {
            appStore.setMenuIsOpen(false);
            appStore.setFilterIsOpen(!appStore.filterIsOpen);
            return;
        }
        appStore.setFilterIsOpen(!appStore.filterIsOpen);
    }

    logout = () => {
        const { props: { userStore } } = this;
        userStore.logout();
    }

    render() {
        const {
            props: {
                userStore,
                appStore,
                sideBarContent: SideBarContent,
                children,
            },
            logout,
        } = this;
        return (
            <div
                className={`
                    ${styles.container}
                    ${appStore.menuIsOpening === true ? styles.menuIsOpening : ''}
                    ${appStore.menuIsOpen === true ? styles.menuIsOpen : ''}
                `}
            >
                <div className={`${styles.mobileHeader}`}>
                    <div className={`${styles.mobileHeaderInner}`}>
                        <button
                            className={`${styles.mobileHeaderMenu}`}
                            type="button"
                            onClick={this.handleMenu}
                        >
                            <Menu
                                fill={appStore.menuIsOpen === false ? '#fff' : '#fed400'}
                            />
                        </button>
                        <div
                            className={styles.mobileHeaderLogo}
                        >
                            <Link
                                className={styles.logo}
                                to="/"
                            >
                                <img src={logo} alt="BBDO" />
                            </Link>
                        </div>
                        <button
                            className={`${styles.mobileHeaderFilter}`}
                            type="button"
                            onClick={this.handleFilter}
                        >
                            <Filter
                                fill={appStore.filterIsOpen === false ? '#fff' : '#fed400'}
                            />
                        </button>
                    </div>
                </div>
                <div
                    className={`${styles.sideBar}`}
                >
                    <div
                        className={styles.menu}
                    >
                        <Link
                            className={styles.logo}
                            to="/"
                        >
                            <img src={logo} alt="BBDO" />
                        </Link>
                        <MenuComponent />
                        <div
                            className={styles.footerSideBar}
                        >
                            <div
                                className={styles.footerSideBarText}
                            >
                                {userStore.userEmail}
                            </div>
                            <div>
                                <WhiteButton
                                    className={styles.logout}
                                    text="Выйти"
                                    onClick={logout}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.contentSideBar}>
                        <SideBarContent />
                    </div>
                </div>
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        );
    }
}

export default PageWrapper;
