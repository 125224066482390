import Form from '../Form/FormModel';
import lastId from '../../utils/last-id';

class EditAnswer extends Form {
    // eslint-disable-next-line class-methods-use-this
    setup() {
        return {
            fields: [{
                name: 'id',
                value: '',
                id: lastId(),
            }, {
                name: 'answer',
                label: 'Ответ пользователя',
                placeholder: '',
                rules: 'required|min:1|max:255',
                value: '',
                id: lastId(),
            }, {
                name: 'reaction',
                label: 'Реакция бота',
                placeholder: '',
                rules: 'required_without_all:image,oldImage|min:1|max:255',
                value: '',
                id: lastId(),
            }, {
                name: 'image',
                type: 'file',
                label: 'Прикрепить изображение',
                rules: 'required_without_all:reaction,oldImage',
                value: '',
                id: lastId(),
            }, {
                name: 'oldImage',
                value: '',
                id: lastId(),
            }],
        };
    }
}

export default EditAnswer;
