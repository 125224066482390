import React from 'react';
import PageWrapper from '../../components/PageWrapper';
import SideBar from '../../components/SideBarContent/Accounts';
import Accounts from '../../components/Accounts';

import defaultStyles from '../../styles/default.scss';


class Questions extends React.Component {
    render() {
        return (
            <PageWrapper sideBarContent={SideBar}>
                <div className={defaultStyles.pageContainer}>
                    <Accounts />
                </div>
            </PageWrapper>
        );
    }
}

export default Questions;
