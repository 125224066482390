/* global Headers, FormData, fetch */
import Token from './entities/Token';
import Request from '../../utils/request';
import withEventEmitter from '../../utils/with-event-emitter';
import { AUTH_TOKEN_UPDATE_LAG, BE_HOST } from '../../constants/constants';
import RequestError from '../../errors/request-error';

@withEventEmitter
class UserService extends Request {
    timeoutId = null;

    authToken = new Token();

    request = new Proxy(this.request, {
        get: (target, name) => {
            if (typeof target[name] !== 'function') {
                return Reflect.get(target, name);
            }
            return (path, options = {}) => (
                Reflect.get(target, name)(path, options)
                    .then((response) => {
                        if (response.auth) {
                            const { auth } = response;
                            this.token = { ...auth, value: auth.access_token };
                            return Promise.resolve(response);
                        }
                        return Promise.resolve(response);
                    })
                    .catch((err) => {
                        this.emit('request:error', err.message);
                        return Promise.reject(err);
                    })
            );
        },
    });

    authorizedRequest = new Proxy(this.request, {
        get: (target, name) => {
            if (typeof target[name] !== 'function') {
                return Reflect.get(target, name);
            }
            return (path, options = {}) => (
                Reflect.get(target, name)(
                    path,
                    {
                        ...options,
                        auth: {
                            bearer: this.token,
                        },
                    },
                )
                    .catch((err) => {
                        if (err.statusCode === 401) {
                            this._resetToken();
                        }
                        this.emit('request:error', err.message);
                        return Promise.reject(err);
                    })
            );
        },
    });

    constructor(storageService) {
        super();
        this.storageService = storageService;
        this.token = storageService.get('auth-token');
    }

    get token() {
        const { authToken } = this;
        return authToken.token;
    }

    set token(value) {
        const { authToken, storageService } = this;
        authToken.token = value;
        const newToken = this.token;
        if (newToken) {
            storageService.save('auth-token', this.authToken.options);
        }
    }

    _setTokenUpdateTimeout() {
        const { authToken: { options: { expiresAt } }, timeoutId } = this;
        if (expiresAt === undefined) return;
        if (timeoutId) clearTimeout(timeoutId);
        const timeLeft = (expiresAt - Date.now()) - AUTH_TOKEN_UPDATE_LAG;
        const timeout = timeLeft > 0 ? timeLeft : 0;
        this.timeoutId = setTimeout(this._updateToken.bind(this), timeout);
    }

    _updateToken() {
        this.authorizedRequest.put('auth')
            .then((data) => {
                const { auth } = data;
                this.token = { ...auth, value: auth.access_token };
                return Promise.resolve(this.token);
            })
            .catch((err) => {
                // 401 is already handled in authorizedRequest
                if (err.statusCode !== 401) {
                    this._resetToken();
                }
            });
    }

    _resetToken() {
        const { authToken, storageService, timeoutId } = this;
        authToken.reset();
        storageService.remove('auth-token');
        clearTimeout(timeoutId);
        this.emit('user:logout');
    }

    login(email, password) {
        return this.request.post(
            'login',
            {
                form: {
                    email,
                    password,
                },
            },
        );
    }

    getQuestions() {
        return this.authorizedRequest.get('questions');
    }

    getQuestion(id) {
        return this.authorizedRequest.get(`questions/${id}`);
    }

    addQuestion(question) {
        return this.authorizedRequest.post('questions', {
            form: {
                text: question,
            },
        });
    }

    editQuestion(id, question) {
        return this.authorizedRequest.post(`questions/${id}`, {
            form: {
                _method: 'put',
                text: question,
            },
        });
    }

    deleteQuestion(id) {
        return this.authorizedRequest.delete(`questions/${id}`);
    }

    addAnswer(questionId, text, reaction, attachment) {
        const headers = new Headers();
        const body = new FormData();
        headers.append('Authorization', `Bearer ${this.token}`);
        headers.append('Accept-Language', 'ru');
        body.append('text', text);
        if (reaction) body.append('reaction', reaction);
        if (attachment) body.append('attachment', attachment);
        return fetch(`${BE_HOST}/questions/${questionId}/answers`, {
            method: 'POST',
            headers,
            body,
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return response.json()
                    .then((data) => {
                        const error = new Error('Unexpected errror');
                        error.statusCode = response.status;
                        error.error = typeof data === 'object' ? JSON.stringify(data) : data;
                        throw error;
                    })
                    .catch(e => Promise.reject(e));
            })
            .catch((e) => {
                const error = new RequestError(e);
                if (error.statusCode === 401) {
                    this._resetToken();
                }
                this.emit('request:error', error.message);
                return Promise.reject(error);
            });
    }

    editAnswer(questionId, id, text, reaction, attachment, oldImage) {
        const headers = new Headers();
        const body = new FormData();
        headers.append('Authorization', `Bearer ${this.token}`);
        headers.append('Accept-Language', 'ru');
        body.append('text', text);
        body.append('_method', 'PUT');
        if (reaction) body.append('reaction', reaction);
        if (attachment) body.append('attachment', attachment);
        if (!oldImage) body.append('attachment_removed', 1);
        return fetch(`${BE_HOST}/questions/${questionId}/answers/${id}`, {
            method: 'POST',
            headers,
            body,
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return response.json()
                    .then((data) => {
                        const error = new Error('Unexpected errror');
                        error.statusCode = response.status;
                        error.error = typeof data === 'object' ? JSON.stringify(data) : data;
                        throw error;
                    })
                    .catch(e => Promise.reject(e));
            })
            .catch((e) => {
                const error = new RequestError(e);
                if (error.statusCode === 401) {
                    this._resetToken();
                }
                this.emit('request:error', error.message);
                return Promise.reject(error);
            });
    }

    deleteAnswer(questionId, id) {
        return this.authorizedRequest.delete(`questions/${questionId}/answers/${id}`);
    }

    getAccounts() {
        return this.authorizedRequest.get('accounts');
    }

    toggleAccountPrivileges(id, admin) {
        return this.authorizedRequest.patch(`accounts/${id}`, {
            form: {
                admin,
            },
        });
    }

    getHistory() {
        return this.authorizedRequest.get('votings');
    }

    deleteVoting(id) {
        return this.authorizedRequest.delete(`votings/${id}`);
    }
}

export default UserService;
