import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Header.scss';

class Header extends Component {
    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.element,
    };

    static defaultProps = {
        title: '',
        children: null,
    };

    render() {
        const { props: { title, children } } = this;
        return (
            <div className={styles.header}>
                <div className={styles.header__action}>
                    {children}
                </div>
                <div className={styles.header__title}>
                    {title}
                </div>
            </div>
        );
    }
}

export default Header;
