import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

import textFieldStyles from './Field.scss';
import defaultStyles from '../../styles/default.scss';

class TextField extends Component {
    static propTypes = {
        autoComplete: PropTypes.oneOf(['on', 'off']),
        type: PropTypes.oneOf(['text', 'password', 'number']),
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        value: PropTypes.string,
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        error: PropTypes.string,
        showLabel: PropTypes.bool,
        showError: PropTypes.bool,
    };

    static defaultProps = {
        autoComplete: 'off',
        type: 'text',
        disabled: false,
        readOnly: false,
        value: '',
        label: '',
        placeholder: '',
        onFocus: null,
        onChange: null,
        onBlur: null,
        onClick: null,
        error: '',
        showLabel: true,
        showError: true,
    }

    render() {
        const {
            id,
            label,
            error,
            showLabel,
            showError,
            ...rest
        } = this.props;
        return (
            <div
                className={`
                    ${textFieldStyles.inputContainer}
                    ${error ? textFieldStyles.inputContainerError : ''}
                `}
            >
                {
                    showLabel
                        ? (
                            <label
                                htmlFor={id}
                                className={textFieldStyles.label}
                            >
                                {label}
                            </label>
                        )
                        : null
                }
                <Input
                    id={id}
                    className={textFieldStyles.input}
                    {...rest}
                />
                {
                    showError
                        ? (
                            <div className={defaultStyles.inputErrorText}>
                                <span>
                                    {error}
                                </span>
                            </div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default TextField;
