import { observable, computed, action } from 'mobx';
import Answer from './AnswerItemModel';

class Question {
    @observable _id;

    @observable _text;

    @observable _answers = [];

    @observable _deleteId;

    @observable _deleting = false;

    constructor(root, { id, text, answers }) {
        this.root = root;
        this.id = id;
        this.text = text;
        this.answers = answers;
    }

    @computed
    get text() {
        return this._text || '';
    }

    set text(text) {
        this._text = text;
    }

    @computed
    get id() {
        return this._id || null;
    }

    set id(id) {
        this._id = id;
    }

    @computed
    get answers() {
        return this._answers || [];
    }

    set answers(answers = []) {
        const { root, id } = this;
        answers.forEach((answer) => {
            this._answers.push(new Answer(root, id, answer));
        });
    }

    @computed
    get deleteId() {
        return this._deleteId || undefined;
    }

    set deleteId(answerId) {
        this._deleteId = answerId;
    }

    @computed
    get deleting() {
        return this._deleting;
    }

    set deleting(status) {
        this._deleting = status;
    }

    @action
    setDeleteAnswerId(answerId) {
        this.deleteId = answerId;
    }

    @action
    addAnswer(answer) {
        const { root, id } = this;
        this._answers.push(new Answer(root, id, answer));
    }

    @action
    removeAnswer(index) {
        this._answers.splice(index, 1);
    }

    // eslint-disable-next-line class-methods-use-this
    edit({ question }) {
        const { root: { userService }, id } = this;
        return userService.editQuestion(id, question)
            .then(() => {
                this.text = question;
            })
            .catch(() => {});
    }

    // eslint-disable-next-line class-methods-use-this
    createAnswer({
        answer,
        reaction,
        image,
        position,
    }) {
        const { root: { userService }, id } = this;
        return userService.addAnswer(id, answer, reaction, image, position)
            .then((response) => {
                this.deleteId = undefined;
                this.addAnswer(response);
            })
            .catch(() => {});
    }

    deleteAnswer() {
        const { root: { userService }, deleteId, id } = this;
        if (!deleteId) return Promise.resolve();
        this.deleting = true;
        return userService.deleteAnswer(id, deleteId)
            .then(() => {
                const index = this.answers.findIndex(item => item.id === deleteId);
                this.deleteId = undefined;
                this.removeAnswer(index);
            })
            .catch(() => {})
            .finally(() => {
                this.deleting = false;
            });
    }
}

export default Question;
