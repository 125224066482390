import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

import textFieldStyles from './Field.scss';
import styles from './Radio.scss';
import defaultStyles from '../../styles/default.scss';

class RadioField extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        value: PropTypes.string,
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        checked: PropTypes.bool,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        error: PropTypes.string,
        showLabel: PropTypes.bool,
        showError: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        readOnly: false,
        value: '',
        label: '',
        checked: false,
        placeholder: '',
        onFocus: null,
        onChange: null,
        onBlur: null,
        onClick: null,
        error: '',
        showLabel: true,
        showError: true,
    }

    render() {
        const {
            id,
            label,
            error,
            showLabel,
            showError,
            checked,
            ...rest
        } = this.props;
        return (
            <div
                className={`
                    ${textFieldStyles.inputContainer}
                    ${error ? textFieldStyles.inputContainerError : ''}
                `}
            >
                <Input
                    id={id}
                    {...rest}
                    type="radio"
                    checked={checked}
                    className={styles.input}
                />
                {
                    showLabel
                        ? (
                            <label
                                htmlFor={id}
                                className={styles.label}
                            >
                                {label}
                            </label>
                        )
                        : null
                }
                {
                    showError
                        ? (
                            <div className={defaultStyles.inputErrorText}>
                                <span>
                                    {error}
                                </span>
                            </div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default RadioField;
