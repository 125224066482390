import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Preloader from '../Preloader';
import Header from '../Header';
import Account from './Account';

import styles from './Accounts.scss';

@inject('userStore')
@observer
class Accounts extends React.Component {
    static propTypes = {
        userStore: PropTypes.shape({
            accounts: PropTypes.shape({
                loadAccounts: PropTypes.func,
                accounts: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    username: PropTypes.string,
                    role: PropTypes.string,
                })),
                toggling: PropTypes.bool,
                loading: PropTypes.bool,
            }),
        }).isRequired,
    };

    componentDidMount() {
        const { props: { userStore: { accounts } } } = this;
        accounts.loadAccounts();
    }

    render() {
        const {
            props: {
                userStore: { accounts: { loading, accounts = [] } },
            },
        } = this;
        return (
            <React.Fragment>
                <div className={styles.accounts}>
                    <Preloader progress={loading} />
                    <Header title="Пользователи" />
                    <table className={styles.accounts__list}>
                        <tbody>
                            <tr>
                                <th>Пользователь</th>
                                <th>ID</th>
                                <th>Роль</th>
                                <th>Действие</th>
                            </tr>
                            {
                                accounts.map(account => (
                                    <Account key={`account-${account.id}`} account={account} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

export default Accounts;
