import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Preloader from '../Preloader';
import Header from '../Header';
import HistoryItem from './HistoryItem';

import styles from './History.scss';

@inject('userStore')
@observer
class History extends React.Component {
    static propTypes = {
        userStore: PropTypes.shape({
            history: PropTypes.shape({
                loadHistory: PropTypes.func,
                deleteVoting: PropTypes.func,
                deleting: PropTypes.bool,
                items: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number,
                    date: PropTypes.string,
                    question: PropTypes.string,
                    results: PropTypes.array,
                })),
                loading: PropTypes.bool,
            }),
        }).isRequired,
    };

    componentDidMount() {
        const { props: { userStore: { history } } } = this;
        history.loadHistory();
    }

    deleteVoting = (id) => {
        const { props: { userStore: { history } } } = this;
        history.deleteVoting(id);
    }

    render() {
        const {
            props: {
                userStore: {
                    history: {
                        loading,
                        deleting,
                        items = [],
                    },
                },
            },
            deleteVoting,
        } = this;
        return (
            <React.Fragment>
                <div className={styles.history}>
                    <Preloader progress={loading} />
                    <Header title="Статистика" />
                    <table className={styles.history__list}>
                        <tbody>
                            <tr>
                                <th>Дата</th>
                                <th>Вопрос</th>
                                <th>Ответы</th>
                                <th>Действие</th>
                            </tr>
                            {
                                items.map(item => (
                                    <HistoryItem
                                        key={`history-${item.id}`}
                                        item={item}
                                        deleting={deleting}
                                        deleteVoting={deleteVoting}
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

export default History;
